import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as TRANSLATIONS_EN from './locales/en/en.json'
import * as TRANSLATIONS_ES from './locales/es/es.json'


export const AVAILABLE_LANGUAGES = [
  { code: 'en', label: 'English' },
  { code: 'es', label: 'Spanish' }
]

const currentLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: currentLanguage,
  resources: {
    en: {
      translations: {
        ...TRANSLATIONS_EN.default,
      }
    },
    es: {
      translations: {
        ...TRANSLATIONS_ES.default,
      }
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es'];

export default i18n;
