/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import "./SchedulingTemplete.scss";
import axios from "axios";
import {
    ZOOMMEETING_DETAILS, BASE_URL
} from "../../api/baseURL";
import * as Utility from "../../utils/utility";

const SchedulingTemplate = (props) => {
    const target001 = `${BASE_URL}${require('../../assets/img/namlogo.png').default}`;
    const caseType = props.caseDetails && props.caseDetails.caseType && props.caseDetails.caseType.toUpperCase();
    const [zoomdetails, setzoomdetails] = useState({});
    ////const [firms, setfirms] = useState({});
    const getZoomMeetingDetails = (hearingCode) => {
        const url = `${ZOOMMEETING_DETAILS}?hearingCode=${hearingCode}`;
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {                    
                    if (res.data.length > 0) {                       
                        let zoommeetingresult = res.data[0];
                        let tmpfirms = {};
                        if(zoommeetingresult.attendees!=null && zoommeetingresult.attendees!==undefined){
                        for (let i = 0; i < zoommeetingresult.attendees.length; i++) {
                            if (!!zoommeetingresult.attendees[i]) {
                                tmpfirms.p_ref = zoommeetingresult.attendees[i].p_ref==null?'':zoommeetingresult.attendees[i].p_ref.trim();
                                if (zoommeetingresult.attendees[i].clients.length > 0) {
                                    if (zoommeetingresult.attendees[i].clients[0].firms) {
                                        tmpfirms.firms = zoommeetingresult.attendees[i].clients[0].firms[0];
                                    }
                                }
                            }
                        }
                    }
                        setzoomdetails(res.data[0]);
                        ////setfirms(tmpfirms);
                        props.callBackForZoomDetails(res.data[0]);
                        props.callBackForAttenddiesSchedular(res.data[0].zoom_attendees);

                    }
                    //props.callBackForAttenddiesSchedular("Attendies Testing");
                }
            });
    }
    useEffect(() => {
        getZoomMeetingDetails(props.hearingCode);
    }, [props.hearingCode]);
    return (
        <>
            <div id="schedulingTemplate" style={{ display: "block" }}>
                <div id="htmldoc">
                    <div id="page1-div" style={{ border: '2px solid #c7c7c7', padding: '10px' }}>
                        <img width="300" height="100" src={target001} alt="background image" />

                        {/* <p style={{}} className="ft10">NATIONAL ARBITRATION AND MEDIATION</p> */}
                        <hr style={{ border: '1px double #c7c7c7' }} />
                        <br />
                        <table>
                            <tr>
                                <td><p style={{}} className="ft12"><b>SCHEDULE : </b></p></td>
                                <td><p style={{}}
                                    className="ft12"><b>{props.caseDetails && props.caseDetails.hearingOfficerName},</b></p>
                                </td>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <td> <p style={{}} className="ft12"><b>DATE :</b></p></td>
                                <td><p style={{}}
                                    className="ft12"><b>{props.caseDetails && Utility.getdate(props.caseDetails.hearingStartTimeUTCFromCMS)}</b></p></td>
                            </tr>
                        </table>


                        <table>
                            <tr>
                                <td><p style={{}} className="ft12"><b>LOCATION : </b></p></td>
                                <td><p style={{}}
                                    className="ft17"><b>{props.caseDetails && props.caseDetails.hearingLocation && props.caseDetails.hearingLocation.toUpperCase()}</b></p>
                                </td>
                            </tr>
                        </table>
                        <br />
                        <hr style={{ border: '1px double #c7c7c7' }} />

                        <table>
                            <tr>
                                <td style={{ width: '10%' }}><p style={{}} className="ft12"><b>TIME</b></p></td>
                                <td style={{ width: '80%' }}><p style={{}} className="ft12"><b>CASE CAPTION</b></p></td>
                                <td style={{ width: '10%' }}><p style={{}} className="ft12"><b>TYPE / ISSUE</b></p></td>
                            </tr>
                        </table>
                        <hr style={{ border: '1px double #c7c7c7' }} />
                        <br />
                            
                        <table>
                            <tr>
                                <td style={{ width: '10%', padding: '10px', verticalAlign: 'baseline' }}>
                                    <p style={{}}
                                        className="ft13">{props.caseDetails && new Date(props.caseDetails.hearingStartTimeUTCFromCMS).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} </p></td>
                                <td style={{ width: '83%' }}><p style={{}}
                                    className="ft16"><b>{props.caseDetails && props.caseDetails.caseName}<br />NAM ID: {props.caseDetails && props.caseDetails.caseReference}</b> </p>
                                    {(props.caseDetails && props.caseDetails.hearingType === "VIDEO-CONF") && <>                             <p style={{}}
                                        className="ft12"><b>*** <i>THIS HEARING HAS A ZOOM VIDEO-CONFERENCE!!! *** </i></b></p>
                                        <p style={{}}
                                            className="ft14"><i><b>ATTENDING VIA VIDEO-CONFERENCE:</b></i></p></>
                                    }
                                      <p className="ft18">                                         
                                            <i>
                                                <b>
                                                    {zoomdetails.zoom_attendees}<br />                                                   
                                                </b>
                                            </i>
                                        </p>
                                   
                                     {/* {props.pmaDetails && props.pmaDetails.partiesdtl &&(
                                        <p className="ft18">
                                            <i><b>{props.pmaDetails.partiesdtl.name}<br />{ props.pmaDetails.partiesdtl.email}
                                            <br />FIRM: {props.pmaDetails.partiesdtl.firmCode}</b></i>
                                        </p>
                                    )}
=======================================================================
                                    {props.pmaDetails?.partiesdtl && (
                                                                            
                                        <p className="ft18">                                         
                                            <i>
                                                <b>
                                                    {props.pmaDetails?.partiesdtl?.name}<br />
                                                    {props.pmaDetails?.partiesdtl?.email}<br />
                                                    FIRM: {props.pmaDetails?.partiesdtl?.firmCode || ''}
                                                </b>
                                            </i>
                                        </p>
                                    )}
 */}
                                    {(props.caseDetails && props.caseDetails.hearingType === "VIDEO-CONF") && Object.keys(zoomdetails).length !== 0 ?
                                        <div>
                                            {!!zoomdetails && !!zoomdetails.attendees && zoomdetails.attendees.map((s) => (
                                                <p style={{}} className="ft14"><i><b>{s.attendee_email}</b></i></p>
                                            ))}

                                            <p style={{}} className="ft18"><i>
                                                <b>UC Dial-In:{zoomdetails.meeting_id}@zoomcrc.com<br />Zoom Meeting Link:</b></i></p>
                                            <span style={{ wordBreak: "break-all" }} className="ft15">
                                                <a href={zoomdetails.meetingURL}><span>{zoomdetails.join_url}</span></a>
                                            </span>
                                            <p style={{}} className="ft18">
                                                <i><b>Zoom Meeting ID:  {zoomdetails.meeting_id}<br />Meeting Password: {zoomdetails.password}<br />
                                                    Host Key:{zoomdetails.host_key}<br />Alt Dial-In (Audio Only): <br />+1 646 558 8656 US (New York)
                                                    <br />+1 720 707 2699 US</b></i></p>
                                            <p style={{}}
                                                className="ft18"><i><b>Meeting ID:  {zoomdetails.meeting_id}<br />Meeting Password:  {zoomdetails.password}</b></i></p>
                                        </div>

                                        : ""
                                    }
                                    
                                    {props.caseDetails.parties!=null && props.caseDetails.parties!==undefined &&
                                        props.caseDetails.parties.map((party,index) => (
                                            
                                            <>{party?.partyType?.trim()==="PLAINTIFF" ?
                                            <p>Plaintiff Attorney:&nbsp;&nbsp; {party?.partyName}&nbsp;&nbsp;({party?.firmName?.trim()})</p>
                                            
                                            :<p>{party?.partyType?.trim()!=="PLAINTIFF" && party.firmType!=null && party?.firmType!=undefined && party?.firmType?.trim()==="ATTORNEY"? "Attorney": "Adjuster"} for {party?.firmName!=null && party?.firmName!=undefined?party?.firmName?.trim():''}:&nbsp;&nbsp;{party?.partyName}</p>
                                            }
                                            {party.partyReference!==null && party?.partyReference?.trim()!=='' &&<><p>Claim Number:&nbsp;&nbsp; {party.partyReference} <br/></p></>}
                                            {party.counselCode!==null && party.counselCode!=='' && party.counselCode>0 &&<><p>Attorney for {party.firmName}:&nbsp;&nbsp;
                                            {party?.partyType?.trim()==="PLAINTIFF" && <>{party.counselFirst} {party.counselLast} {party.counselSuffix}</>}
                                            {party?.partyType?.trim()!=="PLAINTIFF" && <>{party.counselFirst} {party.counselLast} {party.counselSuffix}&nbsp;({party.counselFirmName})</>}<br/></p></>}
                                            </>
                                        ))
                                    }
                                </td>

                                <td style={{ width: '10%', verticalAlign: 'baseline' }}>
                                    <p style={{}} className="ft12"><b>{caseType}</b></p>
                                    <p style={{}} className="ft13">{props.caseDetails.hearingDuration} hr(s). </p>
                                    {props.caseDetails.billingType !== "" && props.caseDetails.billingType !== null  && props.caseDetails.billingType == 'Flat Rate'  &&<> <p style={{}} className="ft13">[{props.caseDetails.billingType}] </p>
                                    </>}
                                    
                                    {props.cotinuedFrom && props.cotinuedFrom !== "-" &&<>
                                        <p style={{ color: 'red' }} className="ft12">[Continuance]</p>
                                        <p style={{ color: 'red' }} className="ft13">{Utility.getdate(props.cotinuedFrom)}</p>
                                        </>
                                    }
                                       <p style={{}} className="ft13">{props.caseDetails.liabilityDamages !== undefined && props.caseDetails.liabilityDamages !== null && props.caseDetails.liabilityDamages !== ''
                                                                                ? props.caseDetails.liabilityDamages
                                                                                : ""
                                       }</p>
                                </td>

                            </tr>
                        </table>
                    </div >
                    <br/>
                    <br/>
                    <div> <span>If you are having difficulties or need assistance joining your hearing,
                        please contact NAM's Helpdesk for assistance.
                        Phone: (516) 941-3211 Email: helpdesk@namadr.com</span></div>
                </div>
            </div >
        </>
    );
};

export default SchedulingTemplate;
