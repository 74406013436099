import React from "react";
import "./forgotPassword.scss";
import "../../App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertDialog from "../../shared/AlertDialog";
import namlogo from "../../assets/img/namlogo.png";
import {
  FORGOT_PASSWORD_WITH_CODE
} from "../../api/baseURL";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      message: "Please enter your Email Id",
      success: false,
      current: { id: 1 },
      isdisplayAlertError:false,
      formData: [
        {
          key: "email",
          type: "email",
          disabled: false,
          id: "email",
          icon: "user",
          label: "User Name",
      //    validators: [{ name: "required", value: true }],
        },
      ],
    };
  }
  updatedisplayAlert = (e)=>{
    this.setState({isdisplayAlertError:false});
  }
  isValidEmailAddress = (e, value) => {
    this.setState({success:false,show:false});
    const email=e.type==='blur'? e.target.value:e.target[0].value;
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      this.setState({
        show: true,
        message:

        <><p>Please enter an email address in the proper format. </p><br></br>
        <p>For example: yourusername@example.com</p></>,
      });
    }
    return regEmail.test(email);
  };
  submitForm = (e, value) => {
    e.preventDefault();

    const email = e.target[0].value;
    if (email === "") {
      this.setState({ show: true, message: 
        <><p>Please enter an email address in the proper format. </p><br></br>
              <p>For example: yourusername@example.com</p></>,
               });
      return;
    }

    if (this.isValidEmailAddress(e,value)) {
      const body = {
        email: email,
      };
      axios.post(FORGOT_PASSWORD_WITH_CODE, body).then((res) => {
        {
          this.setState({
            show: true,
            success: true,
            message:
              <><p> If this email address matches our records, you will receive an email from us with instructions on resetting your password. If you don't receive this email, please check your junk mail folder. </p><br></br>
              <p>For further assistance contact customer service: Phone: 1-800-358-2550 Email: customerservice@namadr.com</p></>,
          });
     
        }
      });
    }
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }  
  };

  render() {
    return (
      <div className="main-container forgotPage loginPage errorForgotPassword">
        <Grid container justify="center" alignItems="left">
          <Grid item sm={12} md={12} direction="column" className="loginBox">
            <Paper className={""} style={{ textAlign: "left" }}>
              <Snackbar
                open={this.state.show}
     //           autoHideDuration={1800}
                onClose={() =>
                  this.handleClose({ vertical: "bottom", horizontal: "right" })
                }
              >
                <Alert
                  severity={this.state.success ? "success" : "error"}
                >
                  {this.state.message}
                </Alert>
              </Snackbar>
              <div className="loginheadbox">
                <div>
                  <img alt="" src={namlogo} />
                </div>
              </div>
              <div className="padbox1">
                <div className="formStyling">
                  <form onSubmit={this.submitForm} method="post">
                    <div className="form-group" style={{textAlign:'left'}}>
                      <label style={{textAlign:'left',fontWeight:'bold'}}>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onBlur={this.isValidEmailAddress}
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-info" type="submit" disabled={this.state.success}>
                        Submit
                      </button>
                    </div>
                    {this.state.show && this.state.success &&
                    <div className="form-group" style={{textAlign:'left'}}> <div style={{textAlign:'center'}}>   <h1>
                    
                  </h1>
                  </div>
              {/*    <span style={{fontSize:'12px',textAlign:'left'}}>
                    You will receive an email from us with instructions for
                    resetting your password. If you don't receive this email,
                    please check your junk mail folder. For further assistance contact customer service.
                    <br/>
                    Phone:{PHONECUSTOMERSERVICE}                     <br/>
Email: {EMAILCUSTOMERSERVICE}

                    </span> */}
              </div>}
                  
                  </form>
                </div>
                {this.state.isdisplayAlertError === true &&
                    <AlertDialog isdisplayAlert={false} isdisplayAlertError={this.state.isdisplayAlertError} updatedisplayAlertError={this.updatedisplayAlert}></AlertDialog>}
       
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
