import moment from "moment";

export const isZoomLinkEnabled = (hearingDate) => {
  const advancedhearingDate = new Date(hearingDate);
  advancedhearingDate.setHours(advancedhearingDate.getHours() - 1);
  const tomorrow = new Date(advancedhearingDate);
  tomorrow.setDate(advancedhearingDate.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const currentdatetime = new Date();
  return currentdatetime >= advancedhearingDate && currentdatetime <= tomorrow
      ? true
      : false;
};

export const compareValues = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const ConvertDate1 = (date) => {
  return moment(new Date(date)).format("MM/DD/YYYY hh:mm a");
};

export const getPendingdate = (value) => {
  if (value) {
    const date = moment(value, "MM-DD-YYYY").toDate();
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(date);
    return `${day}-${month}-${year}`;
  }
};
export const getComapreDate = (date1, date2) => {

  const dtdate1 = new Date(date1);
  const dtdate2 = new Date(date2);

  return dtdate1 < dtdate2;
};
export const getFulldate=(value)=>{
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(value);
  return date.toLocaleDateString('en-US', options)
};
export const getdate = (value) => {
  if (value) {
    const date = new Date(value);
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(date);
    return `${day}-${month}-${year}`;
  }
};
export const ConvertDatetoEST = (value) => {
  const date = new Date(new Date(value.toLocaleString("en-US", { timeZone: "America/New_York" })));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // Check whether AM or PM
  const newformat = hours >= 12 ? "PM" : "AM";
  // Find current hour in AM-PM Format
  hours = hours % 12;
  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${newformat}`;
};
export const ConvertDate = (value) => {
  const date = new Date(value);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // Check whether AM or PM
  const newformat = hours >= 12 ? "PM" : "AM";
  // Find current hour in AM-PM Format
  hours = hours % 12;
  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${newformat}`;
};
export const nth = function (d) {
  if (d > 3 && d < 21) {
    return "th";
  }

  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const IsHearingOfficer = () => {
  return localStorage.getItem("role") === "Hearing Officer";
};
export const IsCaseClosed = (caseStatus) => {
  return (caseStatus!==null && caseStatus!==undefined && caseStatus!=='undefined' && caseStatus.trim().toLowerCase()  === "closed") ? true: false;
};
export const IsCaseDecision = (caseStatus) => {
  return caseStatus!==null && caseStatus!==undefined && (caseStatus.trim().toLowerCase()  === "closed" ||caseStatus.trim().toLowerCase()  === "settled" || caseStatus.trim().toLowerCase()  === "decision");
};

export const diff_minutes = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};
export const getTimeDiff = (start, end) => {
  const diff = moment.duration(
    moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a"))
  );
  const hr = diff.hours() > 1 ? "Hours" : "Hour";
  const mn = diff.minutes() > 1 ? "Minutes" : "Minute";
  const val = `${diff.hours() < 10 ? "0" + diff.hours() : diff.hours()} ${hr} : ${diff.minutes() < 10 ? "0" + diff.minutes() : diff.minutes()
    } ${mn}`;
  return val;
};
export const toHoursAndMinutesAbr = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  const hr = hours > 1 || hours==0? "Hrs" : "Hr";
  const mn = minutes > 1 || minutes==0? "Mins" : "Min";
  if (isNaN(hours) || isNaN(minutes)) {
    return "";
  } else {
    return `${padTo2Digits(hours)} ${hr} ${padTo2Digits(minutes)} ${mn}`;
  }
};
export const toHoursAndMinutesMonthlyAbr = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  const hr = hours > 1 || hours==0? "Hrs" : "Hr";
  const mn = minutes > 1 || minutes==0? "Mins" : "Min";
  if (isNaN(hours) || isNaN(minutes)) {
    return "";
  } else {
    return `${fixedTo2Digits(hours)} ${hr} ${fixedTo2Digits(minutes)} ${mn}`;
  }
};
export const toHoursAndMinutes = (totalMinutes) => {
  
  const minutes =parseInt(totalMinutes % 60);
  const hours = Math.floor(totalMinutes / 60);
  const hr = hours > 1 || hours==0? "Hours" : "Hour";
  const mn = minutes > 1 || minutes==0? "Minutes" : "Minute";
  if (isNaN(hours) || isNaN(minutes)) {
    return "";
  } else {
    return `${padTo2Digits(hours)} ${hr} ${padTo2Digits(minutes)} ${mn}`;
  }
};
function fixedTo2Digits(num) {
  return num.toFixed(2).toString();
}
function padTo2Digits(num) {
  return num.toString().padStart(2, "");
}

export const convertUTCDateToLocalDate = (date) => {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);

  return newDate;
};


//Start Time sheet Durations impacted by Time zone
export const getUserTimeZone = () => { 
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const userSpecificTimeZone=()=>{
return new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(new Date()).find(part => part.type == "timeZoneName").value;
}

export const convertUTCDateToUserTimeZone=(utcdate)=>{
return new Date(utcdate).toLocaleString('default',{timeZone:getUserTimeZone()});
}


export const convertUTCDateToUserTimeZoneOfPMADecision=(utcdate)=>{
  return new Date(utcdate).toLocaleString('default',{timeZone:'America/New_York'});
  }


export const convertTimeZoneToUTC = (loacltime) => { 
  return new Date(loacltime).toUTCString();
};
//End Time sheet Durations impacted by Time zone

export const AddingTimezoneOffsetToISO = (value) => {
  const date = new Date(value);
  const time = date.getTime();
  const offset = date.getTimezoneOffset();
  const multiplier = offset <= 0 ? 1 : -1;
  const final = time + multiplier * Math.abs(offset * 60000);
  return new Date(final).toISOString().replace(".000Z", "");
};

export const allowOnlyLetters = (value) => {
  return value.replace(/[^a-z &.'"-)(,;:@$%#]/gi, "");
};

export const isValidEmail = (email) => {
  return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) ;
};

export const allowOnlyDigits = (value) => {
  const re = /^[0-9\b]+$/;
  if (value === "" || re.test(value)) {
    return value;
  }
};

export const create_UUID = () => {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
   
}

export const isSpace= (val) => {

    const regexp = /^\S*$/;
    return !regexp.test(val) ? true : false;    
};

export const monthNames=
{
"jan":0,
"feb":1,
"mar":2,
"apr":3,
"may":4,
"jun":5,
"jul":6,
"aug":7,
"sep":8,
"oct":9,
"nov":10,
"dec":11
}
