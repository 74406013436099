import React from "react";
import { BASE_URL } from "../../api/baseURL";
import * as Utility from "../../utils/utility";
import "./pma.scss";
import NumberFormat from "react-number-format";
const PMATemplate = (props) => {
  const getTotalTime = (timesheets) => {

    const totalspentminutes = calculateTotalTime(timesheets);

    return Utility.toHoursAndMinutes(totalspentminutes);

  };
  const calculateTotalTime = (timesheets) => {

    if (timesheets !== undefined && timesheets !== null) {
      const totalpostMedTimehours = timesheets.postMedTimehours;
      const totalrevDocTimehours = timesheets.revDocTimehours
      const totalpostMedTimeminutes = timesheets.postMedTimeminutes
      const totalrevDocTimeminutes = timesheets.revDocTimeminutes
      const totalhearingDuration = timesheets.hearingDuration
      const totalspentminutes =
        totalpostMedTimeminutes +
        totalrevDocTimeminutes +
        (totalhearingDuration < timesheets.hearingScheduledDuration * 60 ? timesheets.hearingScheduledDuration * 60 : totalhearingDuration) +
        totalpostMedTimehours * 60 +
        totalrevDocTimehours * 60;

      return totalspentminutes;
    }
    return 0;
  };

  const dateObj = new Date();
  const date = dateObj.getDate();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][dateObj.getMonth()];
  
  //Grammarly changes...
  function extractTextFromHTML(htmlContent) {
    // Create a temporary DOM element to parse the HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
  
    // Remove all inline style attributes and suggestion spans
    tempElement.querySelectorAll('[style]').forEach(el => el.removeAttribute('style'));
    tempElement.querySelectorAll('.suggestion').forEach(el => el.replaceWith(el.textContent));
  
    // Replace <br> tags with newline characters
    tempElement.querySelectorAll('br').forEach(br => br.replaceWith('\n'));
  
    // Replace <p> tags with newline characters for paragraph separation
    tempElement.querySelectorAll('p').forEach(p => {
        // Get the trimmed text content and add a newline if it's not empty
        const text = p.textContent.trim();
        if (text) {
            let newTextNode = document.createTextNode(text + '\n');
            p.replaceWith(newTextNode);
        } else {
            p.replaceWith('\n'); // Treat empty paragraphs as extra line breaks
        }
    });
  
    // Replace &nbsp; with a regular space
    tempElement.innerHTML = tempElement.innerHTML.replace(/&nbsp;/g, ' ');
  
    // Use textContent to extract the text, keeping spaces but removing excessive whitespace
    let extractedText = tempElement.textContent;
  
    // Normalize npnmultiple spaces and newlines: collapse consecutive spaces and preserve two consecutive newlines
    extractedText = extractedText.replace(/[ \t]+/g, ' '); // Collapse consecutive spaces
    //extractedText = extractedText.replace(/\n{3,}/g, '\n\n'); // Replace 3 or more newlines with exactly 2
  
    // Ensure that there are no unnecessary spaces before or after newlines
    extractedText = extractedText.replace(/ *\n */g, '\n');
  
    return extractedText.trim(); // Trim final result to avoid unnecessary leading/trailing newlines
  }
  //Grammarly changes End...

  const strikeThrough = props.caseUnsettled ? 'none' : 'block';
  const year = dateObj.getFullYear();
  const pmaDetails = props.pmaDetails;
  const dateString = date + Utility.nth(date) + " day of " + month + " " + year;
  let isParties=  pmaDetails["parties"] !== undefined &&  pmaDetails["parties"] !== null &&  pmaDetails["parties"]!==[] &&  pmaDetails["parties"].length>0;
  let indexCount = isParties?0:1;
console.log("isParties,indexCount", pmaDetails["parties"],isParties,indexCount);
  return (<>
    <div id="pmaTemplate" style={{ display: 'block' }}>
      <table width="100%" border="0" cellpadding="0">
        <tr>
          <td>
            <div>
              <img
                alt=""
                src={`${BASE_URL}/static/media/namlogo.b809c154.png`}
                style={{
                  display: "block",
                  width: "300px",
                  height: "100px",
                  marginLeft: "-10px"
                }}
              />
            </div>
          </td>
          <td align="right" style={{paddingRight:'15px'}}>
            <div >
              <span>
                <b>Post Mediation Agreement</b>
              </span>
            </div>
          </td>
        </tr>
      </table>



      <div id="htmldoc" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', }}>
        <br></br>
        <table style={{ width: '100%', textAlign: 'left', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
          <tr>
            <td style={{ width: '10%', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}><label
              htmlFor="staticEmail"
              className=""
              style={{
                color: 'black', textAlign: 'left ',
                fontFamily: 'Calibri, sans-serif', fontSize: '35px !important',
                fontWeight: '600',
                paddingBottom: '5px'
              }}
            >
              Case Name
            </label>
            </td>
            <td>

              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', }}>

                {props.caseDetails["caseName"]}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: '10%' }}><label
              htmlFor="staticEmail"
              className=" col-form-label float-start text-start leftt"
              style={{
                color: 'black', textAlign: 'left ',
                fontFamily: 'Calibri, sans-serif', fontSize: '35px !important',
                fontWeight: '600',
                paddingBottom: '5px'
              }}

            >
              NAM ID
            </label>
            </td>
            <td>

              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', }}>

                {props.caseDetails["caseReference"]}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: '10%' }}><label
              htmlFor="staticEmail"
              className=" col-form-label float-start text-start leftt"
              style={{
                color: 'black', textAlign: 'left ',
                fontFamily: 'Calibri, sans-serif', fontSize: '35px !important',
                fontWeight: '600',
                paddingBottom: '5px'
              }}
            >
              Case Method
            </label>
            </td>
            <td>

              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px !important', }}>

                {props.caseDetails["caseType"]}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: '10%' }}><label
              htmlFor="staticEmail"
              className=" col-form-label float-start text-start leftt"
              style={{
                color: 'black', textAlign: 'left ',
                fontFamily: 'Calibri, sans-serif', fontSize: '35px !important',
                fontWeight: '600',
                paddingBottom: '5px'
              }}
            >
              Hearing Officer
            </label>
            </td>
            <td>

              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px !important', }}>

                {props.caseDetails["hearingOfficerName"]}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}><label
              htmlFor="staticEmail"
              className=" col-form-label float-start text-start leftt"
              style={{
                color: 'black', textAlign: 'left ',
                fontFamily: 'Calibri, sans-serif', fontSize: '35px !important',
                fontWeight: '600',
                paddingBottom: '5px'
              }}
            >
              Date of conference
            </label>
            </td>
            <td style={{ width: '90%' }}>

              <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px !important', }}>

                {Utility.getdate(props.caseDetails["hearingStartTimeUTCFromCMS"])}
              </span>
            </td>
          </tr>

        </table>




        <div

          style={{
            marginTop: "20px", padding: '15px',
            lineHeight: '30px',
            fontFamily: 'Calibri, sans-serif', fontSize: '25px! important',

          }}
          id="htmldoc2"
        >
          <div>
            <span style={{ textAlign: 'left ', fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', }}>
              <div>
                <div style={{ display: strikeThrough }}>

                  The parties hereto,

                  <span style={{ marginLeft: '3px', marginRight: '0px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}>{pmaDetails["partiesName"]}</span>
                  , having submitted the facts and issues to NAM, and the mutually
                  agreed mediator,{' '} 
                 

                  <span style={{ marginRight: '0px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}>{props.caseDetails["hearingOfficerName"]}</span>
                  , have fully and completely resolved the dispute as follows:
                  {Object.keys(pmaDetails).length !== 0 &&
                    pmaDetails?.participants &&
                    pmaDetails.participants !== undefined &&
                    pmaDetails.participants !== null ? (
                    pmaDetails.participants.map((item, index) => {
                      return (
                        <>
                          <div style={{ marginLeft: "0.85rem" }}>
                            <div>


                            <span style={{ marginLeft: '3px', marginRight: '3px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}>{item.defendantName}</span>

                              shall pay to
                              <span style={{ marginLeft: '3px', marginRight: '3px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}>{item.plaintiffName}</span>
                              {" "} 
                              <span style={{ marginLeft: '2px', marginRight: '6px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}> ${""}<NumberFormat
                                                  allowNegative={false}
                                                  disabled={false}
                                                  style={{
                                                    border: "none", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block'
                                                  }}
                                                  required
                                                  thousandSeparator={true}
                                                  name="amtPayable"
                                                  fixedDecimalScale={true}
                                                  value={item.amount}
                                                  decimalScale={2}
                                                  readOnly
                                                /></span>
                            </div>

                          </div>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {props.totalSum !== "0.00" && <>
                    <div className="row">
                      <b>Total Sum</b>: <b>$ <NumberFormat
                                    allowNegative={false}
                                    disabled={true}
                                    style={{ width: "13%" }}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={props.totalSum}
                                    displayType={'text'}
                                  /></b>
                    </div>
                    <br></br></>}
                  inclusive of all costs, disbursements, liens, claims and attorney
                  fees for damages, whether compensatory, liquidated and/or
                  punitive. All payments shall be made
                  <span style={{ marginLeft: '6px', marginRight: '6px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600', whiteSpace: 'pre-line', }}>{pmaDetails.notes}</span>
                </div>
                <div style={{ textAlign: 'left ' }}>
                  <br></br>
                  <div style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: 'bold' }}>Additional Notes</div>

                  <span style={{ marginLeft: '6px', marginRight: '6px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', whiteSpace: 'pre-line', textDecoration: 'none' }}>{extractTextFromHTML(pmaDetails.additionalNotes)}</span>
                </div>
                <div>
                  {props.caseDetails["hearingStatus"] == 'CONTINUED' && (
                    <span>This case is to be Continued.
                    </span>
                  )}

                </div>
                <br />
                <div style={{ display: strikeThrough }}>
                  That no party to this agreement shall at anytime hereafter make
                  any claims against the other, institute any lawsuit against the
                  other, or make any demands for payment from the other for any
                  alleged reason or cause arising out of the facts and issues of the
                  matter herein. Each party releases the other from any and all
                  claims and/or liability arising from this matter.<br></br>
                  <br />
                  That this agreement is final and binding upon any and all parties
                  to this matter and is enforceable in any court of law of general
                  jurisdiction.
                </div>
              </div>
              <div  id="timesheetdetailsDecision" className="timesheetdetailsDecision">
              {pmaDetails.timeSheets !== undefined &&
                pmaDetails.timeSheets !== null && pmaDetails.timeSheets.length > 0 && (
                  <>
                  
                      <div style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: '600' }}>
                        <br /><br />
                        <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', fontWeight: 'bold' }}>Timesheet Details:</span>
                      </div>
                      <table className="decision-timesheet table1" border="1" cellPadding={3} cellSpacing={0}>
                      <thead>
                        <tr>
                          <th scope="col">Hearing date</th>
                          <th scope="col">Time (ET)</th>
                          <th scope="col">Reserved time</th>
                          <th scope="col">Review of documents</th>
                          <th scope="col">
                            Post mediation time
                          </th>
                          <th scope="col">Total hearing time</th>
                        </tr>
                      </thead>
                        <tbody className="font-weight-bold">

                          {pmaDetails.timeSheets.map(
                            (timesheet, index) => {
                              return (
                                <>
                                  <tr 
                                   style={{  }}
                                   className={`${timesheet.hearingCode === props.hearingCode ? 'current-hearing' : 'prev-hearing'}`}>
                                    <td data-label="Hearing Date">{Utility.getdate(timesheet.hearingDate)}</td>
                                    <td data-label="Time">
                                      {timesheet.isCombinedTimeSheet ? <span>
                                        <span>{"Session 1 : " + ((
                                          timesheet.hearingStartTimeUTC && 
                                          new Date(
                                            (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                          )
                                          ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                        )) + " to " + (timesheet.hearingEndTimeUTC && 
                                          new Date(
                                            (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                            
                                          ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                          
                                            )}
                                        </span> <br></br>
                                        <span>{"Session 2 : " + (timesheet.hearingStartTimeAdditionalUTC && 
                                        new Date((timesheet.hearingStartTimeAdditionalUTC.indexOf("GMT") ?
                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeAdditionalUTC) :
                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeAdditionalUTC).toUTCString())
                                      )).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                      ) + " to " + (timesheet.hearingEndTimeAdditionalUTC && 

                                        new Date((timesheet.hearingEndTimeAdditionalUTC.indexOf("GMT") ?
                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeAdditionalUTC) :
                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeAdditionalUTC).toUTCString()))).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                        
                                          
                                          )}</span>
                                      </span> :

                                        ((
                                          timesheet.hearingStartTimeUTC && 
                                          new Date(
                                            (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                              Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                              Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                          )
                                          ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                        )
                                          + "" + (timesheet.hearingDuration !== null && timesheet.hearingDuration > 0
                                            && timesheet.hearingDuration > parseFloat(timesheet?.hearingScheduledDuration) * 60 ? timesheet?.hearingScheduledDuration != 0 ?
                                            (" to " + ((timesheet.hearingEndTimeUTC && 
                                              new Date(
                                                (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                            Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                              ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                          ))) : "" : ""))

                                      }
                                    </td>
                                   
                               
                                    <td data-label="Reserved time">


                                      {/* {Utility.toHoursAndMinutes(

                                        timesheet.hearingDuration
                                      )} */}
                                      {/* {Utility.toHoursAndMinutes(parseFloat(props.caseDetails?.hearingDuration) * 60)} */}

                                      {Utility.toHoursAndMinutes(parseFloat(timesheet?.hearingScheduledDuration) * 60)}
                                    </td>
                                    <td data-label="Review of documents & preparation of decision time">{timesheet.revDocTimehours} Hours {timesheet.revDocTimeminutes} Minutes</td>
                                    <td data-label="Post mediation time">
                                      {timesheet.postMedTimehours}{" "}
                                      Hours{" "}
                                      {timesheet.postMedTimeminutes}{" "}
                                      Minutes
                                    </td>
                                    <td data-label="Total hearing time">{getTotalTime(timesheet)}</td>
                                  </tr>

                                </>
                              );
                            }

                          )}
                        </tbody>
                      </table>
                      <br />
                      <div style={{ display: props.caseUnsettled ? 'none' : 'block' }}>Total time spent on this case:</div>
                      <span className="font-weight-bold" style={{ color: "#337AB7", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: props.caseUnsettled ? 'none' : 'block' }}>
                        {Utility.toHoursAndMinutes(
                          props.caseDetails?.caseDuration
                        )}
                      </span>
                      <br />
                  
                  </>
                )}
                </div>
              <div className="row">
                <div className="col-md-9 col-12">
                  <div style={{ textAlign: 'left ', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough }}>
                    Signature reflect the Parties' agreement with time spent.
                  </div>
                  <div>
                    <label htmlFor="staticEmail" className="right">
                      Dated on this{" "}
                      <span style={{ fontWeight: "bold", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{dateString}</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-check" style={{ display: "none" }}>
                    <input className="form-check-input chckbox" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" for="flexCheckDefault">
                      <b>Include signatories</b>
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <br/>
                {pmaDetails["parties"] !== undefined &&
                  pmaDetails["parties"] !== null
                  ? Array.from(pmaDetails["parties"]).map((c, index) => {
                    if (pmaDetails.parties[index].isExcluded !== false) {
                      return (

                        <div className="mb-1 row" style={{marginTop:'10px',}}>
                          <p>

                           
                            <span style={{ fontWeight: "bold",fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block' }}>{pmaDetails.parties[index].type}{(pmaDetails.parties[index].type=== "Additional Signatory" || pmaDetails.parties[index].type=== "DEFENDANT" || pmaDetails.parties[index].type=== "PLAINTIFF") && ":"}</span>

                            {/* <div style={{ flex: '0 0 16.666667%', paddingLeft: '15px', maxWidth: '16.666667%', textAlign: 'left ', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough,display: 'inline-block' }}> */}
                            {/* <span style={{ fontWeight: "bold",fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block' }}>{pmaDetails.parties[index].type}{(pmaDetails.parties[index].type=== "Additional Signatory" || pmaDetails.parties[index].type=== "DEFENDANT" || pmaDetails.parties[index].type=== "PLAINTIFF") && ":"}</span> */}
                            {/* </div> */}

                            
                            
                            {"  "}
                            <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px !important', display: strikeThrough, display: 'inline-block' }}>{pmaDetails.parties[index].name}</span>

                            <input
                              style={{
                                border: 'none', marginLeft: '10px',
                                borderRadius: '4px', width: '400px', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block'
                              }}
                              id={index}
                              key={index}
                              pmavalue={pmaDetails.parties[index].email.indexOf(",")!==-1? 
                              pmaDetails.parties[index].email.split(",")[0] : 
                              (pmaDetails.parties[index].email.indexOf(";")!==-1 ? 
                              pmaDetails.parties[index].email.split(";")[0] : pmaDetails.parties[index].email)}
                              type="email"
                              placeholder="Email"
                              value={pmaDetails.parties[index].email.indexOf(",")!==-1? 
                              pmaDetails.parties[index].email.split(",")[0] : 
                              (pmaDetails.parties[index].email.indexOf(";")!==-1 ? 
                              pmaDetails.parties[index].email.split(";")[0] : pmaDetails.parties[index].email)}
                            />
                          </p>
                        </div>

                      );
                    }
                  })
                  : ""}
              </div>
              <br/><br/>
              <div style={{ pageBreakAfter: 'always', breakAfter: 'always' }} className="pagebreakafter"></div>
              <div style={{ breakBefore: 'always', pageBreakInside: 'avoid', }} className="pagebreak">
                <div>
                  <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', marginTop: '10px', }}>
                    Acknowledged:
                  </span>
                  <span style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', marginTop: '10px', display: strikeThrough }}>
                    This constitutes the complete resolution of all
                    claims herein
                  </span>
                  <p style={{ fontFamily: 'Calibri, sans-serif', fontSize: '25px! important', }}>
                    <span style={{ fontWeight: "bold",fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', display: strikeThrough, display: 'inline-block' }}>Hearing Officer: &nbsp;</span>
                    <span>{props.caseDetails["hearingOfficerName"]}&nbsp;</span>
                    <span style={{marginLeft:'1rem'}}>{
                      props.hearingOfficerEmail
                    }</span>
                  </p>
                </div>
                {/*Docusign AnchorTag */}
                <div id="signatureDecision" className='row' style={{ marginTop: '5rem', textAlign: 'left', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginTop: "3rem", marginBottom: "3rem" }}>
                  {

                    pmaDetails["parties"] !== undefined &&

                      pmaDetails["parties"] !== null

                      ? Array.from(pmaDetails["parties"]).map((c, index) => {

                        indexCount = index + 2;

                        return (

                          <>
                            <div
                              className="col-md-3"
                              style={{
                                color: "white",
                                marginTop: "4rem",

                                border: "0px solid #373737",
                              }}
                            >
                              ${`**signature_${index + 1}**`}
                            </div>
                            <div style={{ width: '100% !important' }}><div

                              style={{
                                color: "white",
                                marginTop: "1rem",
                                border: "0px solid #373737",
                                width: '50% !important',
                                display: 'inline-block',

                              }}
                            >
                              ${`**fullname_${index + 1}**`}
                            </div>
                              
                              <div
                                style={{
                                  color: "white",
                                  marginTop: "1rem",
                                  border: "0px solid #373737",
                                  width: '50% !important',
                                  display: 'inline-block',
                                  marginLeft: '37rem'
                                }}
                              >
                                ${`**datesigned_${index + 1}**`}
                              </div></div></>
                        )
                      }) : <></>

                  }

                
                <div className='col-md-3' style={{ color: 'black', border: '0px solid #373737', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginTop: "3rem", marginBottom: "3rem" }}>
                  <div

                    style={{
                      color: "white",
                      marginTop: "1rem",
                      border: "0px solid #373737",
                      flex: 1,

                    }}
                  >${`**signature_${indexCount}**`}</div>
                  <div style={{}}><div

                    style={{
                      color: "white",
                      marginTop: "1rem",
                      border: "0px solid #373737",
                      width: '50% !important',
                      display: 'inline-block',

                    }}
                  >
                    ${`**fullname_${indexCount}**`}
                  </div>
                    
                    <div
                      style={{
                        color: "white",
                        marginTop: "1rem",
                        border: "0px solid #373737",
                        width: '50% !important',
                        display: 'inline-block',
                        marginLeft: '37rem'
                      }}
                    >
                      ${`**datesigned_${indexCount}**`}
                    </div></div>
                </div>
                {/*Docusign AnchorTag End*/}
                </div>
              </div>
            </span>
          </div>

        </div>
      </div>
    </div>
  </>
  );
};

export default PMATemplate;
